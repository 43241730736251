import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { CostCenterCodeMappingMaintenanceProvider } from "presentation/store/CostCenterCodeMapping/CostCenterCodeMappingMaintenanceProvider";
import { ANAInfoWrapper } from "presentation/view/components/ANAInfo/ANAInfoWrapper";
import { MessageBarWrapper } from "presentation/view/components/MessageBarWrapper";
import CostCenterCodeMappingMasterView from "presentation/view/section/CostCenterCodeMapping/CostCenterCodeMappingMasterView";
import { GridStyles } from "veronica-ui-component/dist/component/core/styled/table.styled";

const CostCenterCodeMappingMaintenanceCont = () => {
    return <ANAInfoWrapper permission={Permission.COST_CENTER_CODE_MAPPING_MAINTENANCE}>
        <MessageBarWrapper>
            <CostCenterCodeMappingMaintenanceProvider>
                <GridStyles />
                <CostCenterCodeMappingMasterView />
            </CostCenterCodeMappingMaintenanceProvider>
        </MessageBarWrapper>
    </ANAInfoWrapper>
}

export default CostCenterCodeMappingMaintenanceCont;