import { ChargeTypeRepoImpl } from "domain/repository/ChargeType/ChargeTypeRepoImpl";
import { CompanyRepoImpl } from "domain/repository/Company/CompanyRepoImpl";
import { CostCenterCodeMappingRepoImpl } from "domain/repository/CostCenterCodeMapping/CostCenterCodeMappingRepoImpl";
import { StandardTariffCodeRepoImpl } from "domain/repository/TariffCode/StandardTariffCodeRepoImpl";
import { TariffTypeRepoImpl } from "domain/repository/TariffCode/TariffTypeRepoImpl";
import { useCostCenterCodeMappingMaintenanceTracked } from "presentation/store/CostCenterCodeMapping/CostCenterCodeMappingMaintenanceProvider";
import { CostCenterCodeMappingMaintenanceVM } from "presentation/viewModel/CostCenterCodeMapping/CostCenterCodeMappingMaintenanceVM";
import { useMemo } from "react";

export const useCostCenterCodeMappingMaintenanceVM = () => {
    const [, setCostCenterCodeMappingMaintenanceState] = useCostCenterCodeMappingMaintenanceTracked();
    const costCenterCodeMappingMainVM = useMemo(() =>
        CostCenterCodeMappingMaintenanceVM({
            dispatch: [setCostCenterCodeMappingMaintenanceState],
            repo: CostCenterCodeMappingRepoImpl(),
            companyRepo: CompanyRepoImpl(),
            standardTariffCodeRepo: StandardTariffCodeRepoImpl(),
            chargeTypeRepo: ChargeTypeRepoImpl(),
            tariffTypeRepo: TariffTypeRepoImpl(),
        }), [setCostCenterCodeMappingMaintenanceState])

    return costCenterCodeMappingMainVM
}

