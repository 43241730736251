import * as yup from 'yup';


export const commonCostCenterCodeMappingMaintenanceValidation: { [x: string]: any; } = {
  costCenterCode: yup.string().required("Missing"),
};

export const createCostCenterCodeMappingMaintenanceValidation: { [x: string]: any; } = {
  ...commonCostCenterCodeMappingMaintenanceValidation,
};

export const createCostCenterCodeMappingMaintenanceValidationSchema = yup.object().shape(createCostCenterCodeMappingMaintenanceValidation);

