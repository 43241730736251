
import { useCostCenterCodeMappingMaintenanceVM } from "presentation/hook/CostCenterCodeMapping/useCostCenterCodeMappingMaintenanceVM";
import { useCostCenterCodeMappingMaintenanceTracked } from "presentation/store/CostCenterCodeMapping/CostCenterCodeMappingMaintenanceProvider";
import { memo, useEffect, useState } from "react";
import { Loader, SliderPanel } from "veronica-ui-component/dist/component/core";
import CostCenterCodeMappingMaintenanceFormPanel from "./CostCenterCodeMappingMaintenanceFormPanel";
import CostCenterCodeMappingTablePanel from "./CostCenterCodeMappingTablePanel";

const CostCenterCodeMappingMasterView: React.FC = () => {
    const [costCenterCodeMappingState] = useCostCenterCodeMappingMaintenanceTracked();
    const [isLoading, setIsLoading] = useState(false);
    const costCenterCodeMappingVM = useCostCenterCodeMappingMaintenanceVM();


    useEffect(() => {
        const initialScreen = async () => {
            setIsLoading(true);
            try {
                const results = await Promise.allSettled([
                    costCenterCodeMappingVM.loadDropdownOption(),
                ]);
                results.forEach((result, index) => {
                    if (index === 0 && result.status === 'fulfilled') {

                    }
                })
            } catch (error) {
                setIsLoading(false);
            }
        }
        initialScreen().then(async () => {
            costCenterCodeMappingVM.onSearch().then((data) => {
                setIsLoading(false)
            }).catch(error => {
                setIsLoading(false)
            });
            setIsLoading(false)
        }).catch(error => {
            setIsLoading(false)
        });

    }, [costCenterCodeMappingVM])



    if (isLoading) return <Loader Indicator="Stripe" size="Large" />;

    return <>
        <div className="main-comp-wrapper im-hide-side-form-draggable">
            {(!costCenterCodeMappingState.isShowDtl) && <CostCenterCodeMappingTablePanel />}
            {
                ((costCenterCodeMappingState.isShowDtl) &&
                    <SliderPanel
                        isOpen={false}
                        leftSectionWidth={"100%"}
                        rightSectionWidth={"0%"}
                        leftChildren={<CostCenterCodeMappingMaintenanceFormPanel />}
                    />
                )
            }
        </div>
    </>
}
export default memo(CostCenterCodeMappingMasterView);