export const costCenterCodeMappingMaintenanceRequiredFieldList: string[] = [
    'costCenterCodeMapping', 
];

export const CostCenterCodeMappingMaintenanceConstant = {
    CATEGORY: "EDI",
    TITLE: "Shipping Line Cost Center Code",
    PRIORITY: "Priority",
    CHARGE_TYPE: "Charge Type",
    SUB_CHARGE_TYPE: "Sub Charge Type",
    TARIFF_TYPE: "Tariff Type",
    TARIFF_CODE: "Tariff Code",
    CHARGEON_COMPANY: "Charge-on Company",
    BILLTO_COMPANY: "Bill-to Company",
    COST_CENTER_CODE: "Cost Center Code",
    ACTIVE_IND: "Active Ind.",

}
