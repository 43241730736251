import { EMPTY_MASTER_COST_CENTER_CODE_MAPPINGANY_MODEL } from "presentation/model/CostCenterCodeMapping/CostCenterCodeMappingMaintenanceModel";
import { useState } from "react";
import { createContainer } from "react-tracked";

const {
    Provider: CostCenterCodeMappingMaintenanceProvider,
    useTracked: useCostCenterCodeMappingMaintenanceTracked
} = createContainer(() => useState(EMPTY_MASTER_COST_CENTER_CODE_MAPPINGANY_MODEL), {concurrentMode: true});
export { CostCenterCodeMappingMaintenanceProvider, useCostCenterCodeMappingMaintenanceTracked };

