import { DataMappingEntity } from "../Common/DataMappingEntity"

export interface CostCenterCodeMappingEntity extends DataMappingEntity {
    id:number,
    chargeOnCompany: string|null,
    billToCompany: string|null,
    chargeType: string|null,
    subChargeType: string|null,
    tariffType: string|null,
    tariffCode: string|null,
    costCenterCode: string,

    [key:string]: string | number | Date | null | undefined
}

export const EMPTY_COST_CENTER_CODE_MAPPING_ENTITY:CostCenterCodeMappingEntity = {
    id: 0,
    chargeOnCompany: "",
    billToCompany: "",
    chargeType: "",
    subChargeType: "",
    tariffType: "",
    tariffCode: "",
    costCenterCode: "",
    priority: 0,
    effectiveDate: null,
    expiryDate: null,
    confirmedDate: null,
    activeInd: "Y",
    mappingType: "CostCenterCodeMappingEntity"
}

