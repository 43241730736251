import { ResponseEntity } from "domain/entity/Common/ResponseEntity";
import { CostCenterCodeMappingEntity } from "domain/entity/CostCenterCodeMapping/CostCenterCodeMappingEntity";
import { axiosDeleteData, axiosGetData, axiosPostData, axiosPutData } from "../axios/AxiosBasicImpl";
import referenceAxiosInstance from "../axios/referenceAxiosInstance";
import { CostCenterCodeMappingRepository } from "./CostCenterCodeMappingRepo";

/**
 * For template
 * @returns 
 */
export const CostCenterCodeMappingRepoImpl = (): CostCenterCodeMappingRepository => {
    const url = '/v1/costCenterCodeMapping';

    const getEntities = async (): Promise<CostCenterCodeMappingEntity[]> => {
        return await axiosGetData(referenceAxiosInstance, url, []).then(res => {
            return res.data
        }).catch(err => {
            return [];
        });

    }

    const getEntityByKey = async (key: string): Promise<CostCenterCodeMappingEntity> => {
        return await axiosGetData(referenceAxiosInstance, `${url}/${key}`, []).then(res => {
            return res.data;
        }).catch(err => {
            return null;
        });
    }

    const createEntity = async (newData: CostCenterCodeMappingEntity): Promise<ResponseEntity> => {
        return await axiosPostData(referenceAxiosInstance, `${url}`, newData);
    }

    const updateEntity = async (updatedData: CostCenterCodeMappingEntity): Promise<ResponseEntity> => {
        return await axiosPutData(referenceAxiosInstance, `${url}`, updatedData);
    }

    const deleteEntity = async (key: string): Promise<ResponseEntity> => {
        return await axiosDeleteData(referenceAxiosInstance, `${url}`, key);
    }

    const onApply = async (rows: CostCenterCodeMappingEntity[]): Promise<ResponseEntity> => {
        const applyUrl = "/v1/costCenterCodeMappingApply"
        return await axiosPostData(referenceAxiosInstance, `${applyUrl}`, rows);
    }

    return {
        getEntities: getEntities,
        getEntityByKey: getEntityByKey,
        createEntity: createEntity,
        updateEntity: updateEntity,
        deleteEntity: deleteEntity,
        onApply: onApply,
    }
}